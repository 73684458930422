<template>
  <div :style="f_calculateScreenStyle()">
    <b-row style="margin: 3px;">
      <b-col cols="6">Yanıt Tipi</b-col>
      <b-col cols="6">
        <select v-model="d_analyzeByLayerDataId.response_type" class="form-control">
          <option value="excel">excel</option>
          <!-- <option value="row_mode">satır mod</option> -->
          <option value="column_mode">kolon mod</option>
        </select>
      </b-col>
    </b-row>
    <b-row style="margin: 3px;">
      <b-col cols="6">Vaka Ana Kural Algoritması Analiz Edilsin mi ?</b-col>
      <b-col cols="6">
        <select v-model="d_analyzeByLayerDataId.case_algorithm" class="form-control">
          <option value="yes">evet</option>
          <option value="no">hayır</option>
        </select>
      </b-col>
    </b-row>
    <b-row style="margin: 3px;">
      <b-col cols="6">Wdmr Key Listesi (virgül ile)</b-col>
      <b-col cols="6">
        <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.wdmr_key">
      </b-col>
    </b-row>
    <b-row style="margin: 3px;">
      <b-col cols="6">Kolon Adı (virgül ile)</b-col>
      <b-col cols="6">
        <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.column_name">
      </b-col>
    </b-row>
    <b-row style="margin: 3px;">
      <b-col cols="6">Client Id Listesi (virgül ile)</b-col>
      <b-col cols="6">
        <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.client_id">
      </b-col>
    </b-row>
    <b-row style="margin: 3px;">
      <b-col cols="6">Sonuç</b-col>
      <b-col cols="6" style="text-align: right;">
        <b-button variant="white" v-on:click="f_analyzeByLayerDataId()">
          <img src="@/icon/1430869.png" style="width: 2em;" /> Analiz
        </b-button>
      </b-col>
    </b-row>
    <template v-if="d_analyzeByLayerDataId.result.length > 0">
      <template v-for="(res, res_ind) in d_analyzeByLayerDataId.result">
        <b-row style="margin: 3px;">
          <b-col cols="12"><strong>{{ res.name }}</strong></b-col>
        </b-row>
        <template v-for="(row, row_index) in res.rows">
          <b-row style="margin: 3px; background-color: #d0fffe;" @click="f_clickThisRow(row_index)">
            <b-col cols="1"><strong>{{ row_index + 1 }}</strong></b-col>
            <b-col cols="11">{{ row.client_id }} ( {{ row.id }} )</b-col>
          </b-row>
          <template v-if="d_selectedRowList.indexOf(row_index) !== -1" v-for="(col, col_index) in row.columns">
            <b-row style="margin: 3px; border-bottom: solid 1px skyblue;">
              <b-col cols="6">
                {{ col.name }}
              </b-col>
              <b-col cols="6">
                <template v-for="(obj, obj_key) in col.obj">
                  <template v-if="obj.list && obj.list.length > 0">
                    <template v-for="(li, li_ind) in obj.list">
                      <div v-if="li.val && li.val.label">
                        {{ li.val.label }}
                      </div>
                      <div v-else>
                        {{ li.val }}
                      </div>
                    </template>
                  </template>
                </template>
              </b-col>
            </b-row>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import PoleStarService from '@/services/polestar';
import store from '@/store';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'rowanalyzebyid',
  data () {
    return {
      d_selectedRowList: [],
      d_projectIdList: '',
      d_analyzeByLayerDataId: {
        'case_algorithm': "no",
        'column_name': "",
        'response_type': "column_mode",
        'layer_id': "",
        'username': "",
        'project_id': "",
        'wdmr_key': "",
        'client_id': "",
        'show': false,
        "result": [],
      },
    };
  },
  components: {},
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {
    if (this.$route.query && this.$route.query.project_id_list) {
      this.d_projectIdList = this.$route.query.project_id_list;
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    f_clickThisRow: function (row_index) {
      if (this.d_selectedRowList.indexOf(row_index) === -1) {
        this.d_selectedRowList.push(row_index);
      } else {
        this.d_selectedRowList.splice(this.d_selectedRowList.indexOf(row_index), 1);
      }
      this.$forceUpdate();
    },
    f_calculateScreenStyle: function () {
      let style = 'background-color: white;';
      style += 'height: ' + (window.innerHeight - 10) + 'px;';
      style += 'overflow-x: auto; overflow-y: auto;';
      return style;
    },
    f_analyzeByLayerDataId: function () {
      this.d_analyzeByLayerDataId.result = [];
      if (this.d_analyzeByLayerDataId.client_id || this.d_analyzeByLayerDataId.wdmr_key) {
        if (this.d_projectIdList) {
          let query = '';
          if (this.d_analyzeByLayerDataId.column_name) {
            if (query) {
              query += '&';
            }
            query += 'column_name=' + this.d_analyzeByLayerDataId.column_name;
          }
          if (this.d_analyzeByLayerDataId.response_type) {
            if (query) {
              query += '&';
            }
            query += 'response_type=' + this.d_analyzeByLayerDataId.response_type;
          }
          if (this.d_analyzeByLayerDataId.layer_id) {
            if (query) {
              query += '&';
            }
            query += 'layer_id=' + this.d_analyzeByLayerDataId.layer_id;
          }
          if (this.d_analyzeByLayerDataId.username) {
            if (query) {
              query += '&';
            }
            query += 'username=' + this.d_analyzeByLayerDataId.username;
          }
          if (this.d_analyzeByLayerDataId.client_id) {
            if (query) {
              query += '&';
            }
            query += 'client_id=' + this.d_analyzeByLayerDataId.client_id;
          }
          if (this.d_analyzeByLayerDataId.wdmr_key) {
            if (query) {
              query += '&';
            }
            query += 'wdmr_key=' + this.d_analyzeByLayerDataId.wdmr_key;
          }
          if (this.d_analyzeByLayerDataId.case_algorithm) {
            if (query) {
              query += '&';
            }
            query += 'case_algorithm=' + this.d_analyzeByLayerDataId.case_algorithm;
          }
          if (this.d_projectIdList) {
            if (query) {
              query += '&';
            }
            query += 'project_id=' + this.d_projectIdList;
          }
          let data = {};
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz devam ediyor. Lütfen bekleyiniz.' } });
          PoleStarService.analyze_by_layer_data_id(query, data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              // console.log(resp.data);
              if (resp.data.status_code === "2000") {
                if (resp.data.excel) {
                  FileSaver.saveAs(resp.data.excel, "AnalyzeByLayerDataId.xlsx");
                  this.d_analyzeByLayerDataId.show = false;
                } else {
                  this.d_analyzeByLayerDataId.result = resp.data.list;
                }
              } else {
                alert(resp.data.status_code, ' ', resp.data.status_message);
              }
              this.$forceUpdate();
            });
        }

      } else {
        alert('wdmr key veya client id yazınız.');
      }
    },
  },
  watch: {}
};

</script>

<style type="text/css"></style>

